import React from 'react'
import styled from "styled-components"

import Title from '../atoms/Title';
import IframeVideo from '../atoms/Video';

const StyledContainer = styled.section` 
    max-width: 960px;
    margin: 4rem auto;
`;

const WelcomeVideo = ({ }) => {
  return (
    <StyledContainer>
        <Title level={2} position={'center'}>Welcome to A GAME ABOVE <br />watch the video</Title>
        <IframeVideo videoUrl='https://player.vimeo.com/video/405845543?h=2997177edd&title=0&byline=0&portrait=0' />
    </StyledContainer>
  )
}

export default WelcomeVideo