import React from "react"
import * as Icon from 'react-feather'

import Infopage from "../components/templates/Infopage"

import SEO from "../components/atoms/Seo"
import Title from "../components/atoms/Title"
import Divider from '../components/atoms/Divider'
import IframeMap from '../components/atoms/Map'
import Button from '../components/atoms/Button'
import Grid from '../components/atoms/Grid'
import GridItem from '../components/atoms/GridItem'
import Box from '../components/atoms/Box'
import WelcomeVideo from "../components/molecules/WelcomeVideo"

const AboutPage = () => {
    return (
    <Infopage
        title='ABOUT US'
        headerColor='Blue'
        leadText='Get in touch with A GAME ABOVE and find out about our team. Everything we do focuses upon customer and community engagement to enhance lifetime value and extend lifecycle. We innovate, optimise and deliver alongside our clients to help make your customers believe.'
        icon="people"
        iconColor='white'
        iconBackground='#A2845C'>
        <SEO 
            title='About A GAME ABOVE'
            description='Get in touch with A GAME ABOVE and find out about our team. Everything we do focuses upon customer and community engagement to enhance lifetime value and extend lifecycle. We innovate, optimise and deliver alongside our clients to help make your customers believe.'
            keywords="marketing agency, experience marketing agency, igaming marketing agency, igaming marketing, gambling marketing, casino marketing, omni channel marketing, online betting marketing, sales promotions agency, Nicolas Fleiderman, ismail vali, paul girvan, Alastair Graham, Joe Witterschein, Stu Dinetz, Heather Roth-Eichenbaum, game above, a game above, marketing, igaming, promos, campaigns"
        />

        <div id='contact'>
            <Divider />
            <Title level={2}>Contact us.</Title>
            <Grid>
                <GridItem size='20%' style={{textAlign: 'center'}}>
                    <Button position='center' background={'#A2845C'} backgroundHover={'#826A4A'} link="mailto:info@agameabove.com?Subject=Contact%20from%20website" externalLink={true}>
                        <Icon.Mail size={24} color={'#fff'} style={{margin: '0 8px -6px 0'}} /> Email us
                    </Button>
                </GridItem>
                <GridItem size='65%'>
                    <IframeMap mapUrl='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3072.801170750648!2d-104.89726068422445!3d39.631680479464094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c87005555bd55%3A0xb017ee6a6e27958c!2s4610%20S%20Ulster%20St%20Suite%20%23150%2C%20Denver%2C%20CO%2080237%2C%20USA!5e0!3m2!1sen!2sse!4v1620795738492!5m2!1sen!2sse'
                    mapTitle='Map' />
                </GridItem>
            </Grid>
        </div>

        <div id='about'>
            <Divider />
            <Box color='grey' fullWidth={true}>
                <Title level={2}>About our team.</Title>
                <p style={{textAlign: 'center', margin: '0 auto 2rem'}}>
                The senior team at A GAME ABOVE have a history of building & maintaining number 1 brands – ranked by market, GGR & active players – and a detailed experience of producing, and protecting by patent and partner exclusivity, entertaining campaigns that drive audience engagement & activity across the value chain, with sustainable, responsible player behaviour throughout.
                Over their professional experience, the team have a proven track record of commercial and creative success across senior industry roles in advisory, betting, consulting, design, gaming, investment banking, lobbying, lottery, marketing and public relations.
                </p>
                
                <WelcomeVideo />
            </Box>
        </div>
    </Infopage>
    )
}

export default AboutPage