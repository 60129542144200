import React from "react"
import styled from "styled-components"

import Title from "../atoms/Title"
import Box from "../atoms/Box"
import Icon from "../atoms/Icon"
import Grid from '../atoms/Grid'
import GridItem from '../atoms/GridItem'

import Container from "../molecules/Container"
import Header from "../organisms/Header"
import Footer from "../organisms/Footer"

import useSticky from '../hooks/Sticky'

import "../layout.css"

const StyledMain = styled.main`
    text-align: center;
    max-width: 960px;
    margin: 0 auto;
    padding-bottom: 96px;

    @media only screen and (max-width: 1000px) {
        padding: 0 8px;
    }
`;

const InfoPage = ({headerColor, title, leadText, icon, iconColor, iconBackground, children}) => {
    const { isSticky, element } = useSticky()

    return (
        <>
            <Header headerColor={headerColor} variant='box' sticky={isSticky}>
                <Box className='clearfix'>
                    <Grid>
                        <GridItem size='70%' gridOrder='2'>
                            <Title level={1} color="#fff" position="left" element={element}>{title}</Title>
                            <p className="lead" style={{color: '#fff'}}>{leadText}</p>
                        </GridItem>
                        <GridItem size='20%' gridOrder="1">
                            {icon && <Icon icon={icon} color={iconColor} background={iconBackground} />}
                        </GridItem>
                    </Grid>
                </Box>
            </Header>
            <Container>
                <StyledMain className={isSticky && 'sticky'}>
                    {children}
                </StyledMain>
            </Container>
            <Footer />
        </>
    )
}

export default InfoPage