import React from "react"
import styled from 'styled-components';

const StyledContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
`;

const IframeVideo = ({videoUrl}) => {
    return(
        <StyledContainer>
            <iframe 
                src={videoUrl}
                title='Welcome to A GAME ABOVE - Watch the video'
                frameBorder="0"
                width='200'
                height='100'
                style={{
                    height: '100%',
                    left: '0',
                    position: 'absolute',
                    top: '0',
                    width: '100%'
                }}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                aria-hidden="false" />
        </StyledContainer>
    )
}

export default IframeVideo
