import React from "react"
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 96px;

    @media only screen and (max-width: 600px) {
        width: 280px;
        margin-top: 32px;
    }
`;

const Divider = ({ ...props }) => (
  <StyledContainer {...props} />
)

export default Divider